<script>
import SwiperCore, {Thumbs, Navigation, Pagination} from "swiper";
import "swiper/swiper-bundle.css";


SwiperCore.use([Thumbs, Navigation, Pagination]);

import Layout from "./../layouts/main";
import appConfig from "../../app.config";

import {authComputed} from "@/state/helpers";
import moment from "moment/moment";
import PaymentService from "@/services/payment-service";

export default {
  page: {
    title: "Subscription plan",
    meta: [{name: "description", content: appConfig.description}],
  },
  data() {
    return {
      thumbsSwiper: null,
      paymentMethod: null,
      subscriptionPlan: 'plan_2'
    };
  },
  computed: {
    ...authComputed,
    existActivePlan () {
      if (!this.currentUser) {
        return false;
      }
      if(!this.currentUser.subscription_period_end) {
        return false;
      }
      return moment(this.currentUser.subscription_period_end).isAfter(new Date());
    },
  },
  components: {
    Layout,
  },
  methods: {
    async pay() {
      const response = await PaymentService.create(this.subscriptionPlan, this.currentUser.email);
      console.log(response);
      location.href = response.data
    },
    showPaymentMethod(method) {
      this.paymentMethod = method;
    },
    showSubscriptionPlan(plan) {
      this.subscriptionPlan = plan
    },
    dateTime(value) {
      return moment(value).format('YYYY-MM-DD');
    },
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-xs-12" v-if="existActivePlan">
        <div class="alert alert-success">
          {{ $t("t-have-active-plan") }}. {{ $t("t-profile-subscription-till") }}: {{ currentUser.subscription_period_end ?? '' }}.
        </div>
      </div>
      <div class="col-xxl-12">
        <div class="card">
          <div class="card-header">
            <h5 class="text-primary">
                {{ $t("t-subscription-plan") }}
            </h5>
          </div>
          <div class="card-body p-4">
            <div class="row">
              <div class="col-xs-12 col-md-5">
                <div class="row">
                  <div class="col-12">
                    <h3>Payment method</h3>
                    <div class="hstack gap-2 flex-wrap">
                      <input type="radio" name="payment-method" class="btn-check" id="coinbase" v-on:click="showPaymentMethod('coinbase')">
                      <label class="btn btn-outline-secondary" for="coinbase">Coinbase</label>

                      <input type="radio" name="payment-method" class="btn-check" id="crypto-currency" v-on:click="showPaymentMethod('crypto')">
                      <label class="btn btn-outline-secondary" for="crypto-currency">Crypto currency</label>
                    </div>
                  </div>
                  <div class="col-12 mt-3">
                    <h3>Subscription plan</h3>
                    <div class="card border card-border-info">
                      <div class="card-header">
                        <h6 class="card-title mb-0" v-show="subscriptionPlan === 'plan_1'">25 USD</h6>
                        <h6 class="card-title mb-0" v-show="subscriptionPlan === 'plan_2'">120 USD</h6>
                        <h6 class="card-title mb-0" v-show="subscriptionPlan === 'plan_3'">210 USD</h6>
                      </div>
                      <div class="card-body">
                        <p class="card-text" v-show="subscriptionPlan === 'plan_1'">{{ $t("t-month-profit") }} 25 USDT</p>
                        <p class="card-text" v-show="subscriptionPlan === 'plan_2'">{{ $t("t-month-profit") }} 20 USDT </p>
                        <p class="card-text" v-show="subscriptionPlan === 'plan_3'">{{ $t("t-month-profit") }} 17.5 USDT </p>

                        <div class="hstack gap-2 flex-wrap">
                          <input type="radio" name="subscription-plan" class="btn-check" id="plan-1" v-on:click="showSubscriptionPlan('plan_1')">
                          <label class="btn btn-outline-secondary" for="plan-1">1 month</label>

                          <input type="radio" name="subscription-plan" class="btn-check" id="plan-2" checked v-on:click="showSubscriptionPlan('plan_2')">
                          <label class="btn btn-outline-secondary" for="plan-2">6 months</label>

                          <input type="radio" name="subscription-plan" class="btn-check" id="plan-3" v-on:click="showSubscriptionPlan('plan_3')">
                          <label class="btn btn-outline-secondary" for="plan-3">12 months</label>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-md-7">
                <!-- Responsive Images -->
                <b-img src="/subscription/payment-screen.png" class="img-fluid border border-2" v-show="paymentMethod == null" alt="Responsive image"></b-img>
                <b-img src="/subscription/payment-screen-coinbase.png" v-show="paymentMethod === 'coinbase'" class="img-fluid border border-2" alt="Responsive image"></b-img>
                <b-img src="/subscription/payment-screen-cryptocurrency.png" v-show="paymentMethod === 'crypto'" class="img-fluid border border-2" alt="Responsive image"></b-img>
              </div>
              <div class="offset-md-3 col-md-6 col-xs-12 mt-3">
                <a href="javascript:void(0);" v-on:click="pay()" class="btn btn-success w-100">
                  {{ $t("t-continue") }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

<!--    <div class="row">-->
<!--      <div class="col-xxl-4 col-lg-6">-->
<!--        <div class="card pricing-box ribbon-box right">-->
<!--          <div class="card-body bg-light m-2 p-4">-->
<!--            <div class="d-flex align-items-center mb-3">-->
<!--              <div class="flex-grow-1">-->
<!--                <h5 class="mb-0 fw-semibold">-->
<!--                  <span v-if="!existActivePlan">{{ $t("t-subscription-period-plan-1") }}</span>-->
<!--                  <span v-if="existActivePlan">{{ $t("t-extend-subscription-period-plan-1") }}</span>-->
<!--                </h5>-->
<!--              </div>-->
<!--              <div class="ms-auto"><h2 class="month mb-0">25 USDT</h2>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="mt-3 pt-2">-->
<!--              <a href="javascript:void(0);" v-on:click="pay('plan_1')" class="btn btn-info w-100">-->
<!--                {{ $t("t-pay") }}-->
<!--              </a>-->
<!--            </div>-->
<!--            <div class="mt-3">-->
<!--              <p class="text-center mb-0">{{ $t("t-month-profit") }} 25 USDT </p>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="col-xxl-4 col-lg-6">-->
<!--        <div class="card pricing-box ribbon-box right">-->
<!--          <div class="card-body bg-light m-2 p-4">-->
<!--            &lt;!&ndash;                                  <div class="ribbon-two ribbon-two-danger"><span>{{ $t("t-popular") }}</span></div>&ndash;&gt;-->
<!--            <div class="d-flex align-items-center mb-3">-->
<!--              <div class="flex-grow-1">-->
<!--                <h5 class="mb-0 fw-semibold">-->
<!--                  <span>{{ $t("t-extend-subscription-period-plan-2") }}</span>-->
<!--                </h5>-->
<!--              </div>-->
<!--              <div class="ms-auto"><h2 class="month mb-0">120 USDT</h2>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="mt-3 pt-2">-->
<!--              <a href="javascript:void(0);" v-on:click="pay('plan_2')" class="btn btn-info w-100">-->
<!--                {{ $t("t-pay") }}-->
<!--              </a>-->
<!--            </div>-->
<!--            <div class="mt-3">-->
<!--              <p class="text-center mb-0">{{ $t("t-month-profit") }} 20 USDT </p>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="col-xxl-4 col-lg-6">-->
<!--        <div class="card pricing-box">-->
<!--          <div class="card-body bg-light m-2 p-4">-->
<!--            <div class="d-flex align-items-center mb-3">-->
<!--              <div class="flex-grow-1">-->
<!--                <h5 class="mb-0 fw-semibold">-->
<!--                  <span v-if="!existActivePlan">{{ $t("t-subscription-period-plan-3") }}</span>-->
<!--                  <span v-if="existActivePlan">{{ $t("t-extend-subscription-period-plan-3") }}</span>-->
<!--                </h5>-->
<!--              </div>-->
<!--              <div class="ms-auto"><h2 class="month mb-0">210 USDT</h2>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="mt-3 pt-2">-->
<!--              <a href="javascript:void(0);" v-on:click="pay('plan_3')" class="btn btn-info w-100">-->
<!--                {{ $t("t-pay") }}-->
<!--              </a>-->
<!--            </div>-->
<!--            <div class="mt-3">-->
<!--              <p class="text-center mb-0">{{ $t("t-month-profit") }} 17.5 USDT </p>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

  </Layout>
</template>
